<template>
    <b-container fluid class="mt-2 px-1 px-sm-3 pb-3 team-page">
        <b-row>
            <b-col cols="" class="">
                <b-row>
                    <b-col cols="">
                        <div class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-3">
                            <h2 class="text-left">{{ title }}</h2>
                            <b-dropdown id="dropdown-divider" text="text" class="load-dropdown">
                                <template slot="button-content">
                                    <b-icon class="dots" icon="three-dots"></b-icon>
                                </template>
                                <b-dropdown-item-button @click="downloadExcel">
                                    <p class="text_secondary cursor-pointer mb-0 f-14">Export CSV</p>
                                </b-dropdown-item-button>
                            </b-dropdown>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <div class="data-table team-data-table pt-3 pt-sm-4 bg-white rounded table-custom-border">
                            <div class="table_row team_table_row">
                                <div class="equipment-border filter-box">
                                    <div class="d-flex mb-0 px-3 px-sm-4">
                                        <b-form-group class="text-left label font-weight-normal search-field mb-0">
                                            <b-input-group class="username-input-field">
                                                <b-form-input type="text" placeholder="Search" v-model="filterFields.search"
                                                    class="unit f-12" @keyup.enter="getTableData()"></b-form-input>
                                                <b-input-group-append>
                                                    <b-button variant="primary" @click="getTableData()">
                                                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                                                                stroke="white" stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-form-group>
                                        <b-form-group class="d-none d-md-block" style="width: fit-content !important;">
                                            <date-picker v-model="filterFields.date" :formatter="momentFormat"
                                                @change="getTableData()" :range-separator="' - '"
                                                @clear="filterFields.date = ''" placeholder="Select Date"
                                                range
                                                :shortcuts="shortcuts"
                                                ></date-picker>
                                        </b-form-group>
                                        <b-form-group v-if="title === 'Active Equipment'">
                                            <b-form-select @change="getTableData()" v-model="filterFields.type" :options="[
                                                {
                                                    value: '',
                                                    text: 'Select Type'
                                                },
                                                {
                                                    value: 'Vehicle',
                                                    text: 'Vehicle',
                                                },
                                                {
                                                    value: 'Trailer',
                                                    text: 'Trailer',
                                                },
                                            ]"></b-form-select>
                                        </b-form-group>
                                        <b-form-group class="text-left w-equipment-box mb-3">
                                            <v-select multiple :options="allTags" class="f-12"
                                                :reduce="(option) => option.id" v-model="filterFields.tags"
                                                placeholder="Select Tags" @input="getTableData()" />
                                        </b-form-group>
                                    </div>
                                </div>
                                <b-table class="mt-2" responsive :fields="fields" :items="items" hover
                                    :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                    :no-local-sorting="true" :busy.sync="isBusy" :show-empty="emptyTable">
                                </b-table>
                                <div class="entries-pagination px-3 px-sm-4">
                                    <div class="d-flex align-items-center flex-wrap">
                                        <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                                            <b-form-select v-model="perPage" :options="pageOptions" />
                                        </div>
                                        <div class="pagination">
                                            <b-pagination v-model="currentPage" :total-rows="rows"
                                                :per-page="perPage"></b-pagination>
                                        </div>
                                        <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">{{ positionText }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';
import * as moment from "moment-timezone";
import 'vue2-datepicker/index.css';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
    name: "ReportsDetailsTable",
    props: ["title", "fields", "getData", "defaultSortField", "exportCsv"],
    components: { DatePicker, vSelect },
    data() {
        return {
            allTags: [],
            rows: 1,
            pageOptions: [
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                },
            ],
            filterFields: {
                search: '',
                date: '',
                type: '',
                tags: [],
                status: ''
            },
            items: [],
            sortBy: undefined,
            emptyTable: false,
            sortDesc: undefined,
            isBusy: false,
            perPage: 10,
            currentPage: 1,
            positionText: "",
            shortcuts: [
                {
                    text: 'This Week',
                    onClick: () => {
                        const today = new Date();
                        const dayOfWeek = today.getDay() || 7;
                        const startOfWeek = new Date(today);
                        startOfWeek.setDate(today.getDate() - dayOfWeek + 1);
                        this.filterFields.date = [startOfWeek, today];
                        this.getTableData()
                    },
                },
                {
                    text: 'This Month',
                    onClick: () => {
                        const today = new Date();
                        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                        this.filterFields.date = [startOfMonth, today];
                        this.getTableData()
                    },
                },
                {
                    text: 'This Year',
                    onClick: () => {
                        const today = new Date();
                        const startOfYear = new Date(today.getFullYear(), 0, 1);
                        this.filterFields.date = [startOfYear, today];
                        this.getTableData()
                    },
                },
                {
                    text: 'Last Week',
                    onClick: () => {
                        const today = new Date();
                        const dayOfWeek = today.getDay() || 7;
                        const startOfLastWeek = new Date(today);
                        startOfLastWeek.setDate(today.getDate() - dayOfWeek - 6);
                        const endOfLastWeek = new Date(today);
                        endOfLastWeek.setDate(today.getDate() - dayOfWeek);
                        this.filterFields.date = [startOfLastWeek, endOfLastWeek];
                        this.getTableData()
                    },
                },
                {
                    text: 'Last Month',
                    onClick: () => {
                        const today = new Date();
                        const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                        const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                        this.filterFields.date = [startOfLastMonth, endOfLastMonth];
                        this.getTableData()
                    },
                },
                {
                    text: 'Last Year',
                    onClick: () => {
                        const today = new Date();
                        const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
                        const endOfLastYear = new Date(today.getFullYear() - 1, 11, 31);
                        this.filterFields.date = [startOfLastYear, endOfLastYear];
                        this.getTableData()
                    },
                },
            ],
            momentFormat: {
                //[optional] Date to String
                stringify: (date) => {
                    return date ? moment(date).format('MMM D, YYYY') : ''
                },
                //[optional]  String to Date
                parse: (value) => {
                    return value ? moment(value, 'MMM D, YYYY').toDate() : null
                }
            },
        }
    },
    methods: {
        ...mapActions(['getAllTags',"setLoader","setNotification"]),
        async getTableData() {
            try {
                this.items = []
                this.isBusy = true
                let url = ""
                let tags = ""
                Object.keys(this.filterFields).map((key) => {
                    if (key === "tags" && this.filterFields[key].length > 0) {
                        this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
                        url += `${key}=${tags}&`
                    }
                    else if (key === 'date' && this.filterFields['date'][0]) {
                        url += `start_date=${moment(this.filterFields['date'][0]).format("YYYY-MM-DD")}&end_date=${moment(this.filterFields['date'][1]).format("YYYY-MM-DD")}&`
                    }
                    else if (this.filterFields[key] !== '' && key !== 'tags' && key !== 'date') {
                        url += `${key}=${this.filterFields[key]}&`
                    }
                })
                if (this.sortBy) {
                    url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
                }
                else {
                    url += `sort_field=${this.defaultSortField}&sort_order=asc&`
                }
                url += `page=${this.currentPage}&page_size=${this.perPage}`
                const data = await this.getData(url);
                if (data.total_entries) {
                    this.rows = data.total_entries
                    this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} to ${this.perPage * (this.currentPage - 1) + data.queryset_count} of ${data.total_entries}`
                }
                else {
                    this.rows = 0
                    this.positionText = `0 to 0 of 0`
                }
                data.records.map((item) => {
                    const tableItem = {}
                    Object.keys(item).map((key) => {
                        if (item[key]) tableItem[key] = item[key]
                        else tableItem[key] = "-"
                    })
                    this.items.push(tableItem)
                })

                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
                this.isBusy = false
            }
            catch (e) {
                this.isBusy = false
                console.log(e)
            }
        },
        async downloadExcel() {
            try {
                this.setLoader(true)
                let url = ""
                let tags = ""
                Object.keys(this.filterFields).map((key) => {
                    if (key === "tags" && this.filterFields[key].length > 0) {
                        this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
                        url += `${key}=${tags}&`
                    }
                    else if (key === 'date' && this.filterFields['date'][0]) {
                        url += `start_date=${moment(this.filterFields['date'][0]).format("YYYY-MM-DD")}&end_date=${moment(this.filterFields['date'][1]).format("YYYY-MM-DD")}&`
                    }
                    else if (this.filterFields[key] !== '' && key !== 'tags' && key !== 'date') {
                        url += `${key}=${this.filterFields[key]}&`
                    }
                })
                if (this.sortBy) {
                    url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
                }
                else {
                    url += `sort_field=${this.defaultSortField}&sort_order=asc&`
                }
                const data = await this.exportCsv(url)
                const blob = new Blob([data], { type: 'text/csv' });
                const csvUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = csvUrl;
                a.download = 'records.csv';
                a.target="_blank"
                document.body.appendChild(a);
                a.click();
                a.remove()
                window.URL.revokeObjectURL(url);
                await this.setNotification({
                    msg: `File Exported Successfully`,
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
            }
            catch (e) {
                this.isBusy = false
                console.log(e)
            }
        },
    },
    computed: {
        ...mapGetters(["company", 'tags'])
    },
    watch: {
        perPage: {
            async handler() {
                await this.getTableData();
            },
            immediate: true,
        },
        currentPage: {
            async handler() {
                await this.getTableData();
            },
        },
        sortBy: {
            async handler() {
                await this.getTableData();
            },
        },
        sortDesc: {
            async handler() {
                await this.getTableData();
            },
        },
        filterFields: {
            handler() {
                this.currentPage = 1
            },
            deep: true
        },
        company: {
            async handler() {
                await this.getAllTags(this.company[0].id);
                this.tags.map((item) => {
                    this.allTags.push({
                        id: item.add_tag,
                        label: item.add_tag,
                    });
                });
            },
            deep: true,
            immediate: true
        }
    }

}
</script>