<template>
  <div class="text-left p-3 bg-white rounded">
    <h3 class="font-weight-bold f-20 mb-4 mt-2 text-left text-black">Integrations</h3>
    <div
      v-if="!showInnerSamsaraPage && !showInnerMotivePage && !showInnerGeoTabPage && !showInnerIsaacPage && !showInnerPsPage">
      <b-row class="mb-5 mt-3">
        <b-col md="6" class="mb-3 mb-md-0">
          <b-card class="table-custom-border h-100">
            <b-card-text class="position-relative">
              <img :src="require('@/assets/samsara.svg')" height="50px" />
              <img class="integration-status" :src="company && company.samsara_refreshtoken
                ? require('@/assets/active-state.svg')
                : require('@/assets/inactive-state.svg')
                " />
              <p class="mt-4 text-tertiary">
                Samsara integration will integrate your drivers, vehicles and
                trailers to our platform.
              </p>
              <hr />
              <div class="text-right">
                <p class="text-main cursor-pointer mb-0" @click="$emit('samsaraStatus', true)">
                  View Integration
                </p>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card class="table-custom-border h-100">
            <b-card-text class="position-relative">
              <img :src="require('@/assets/motive.svg')" height="50px" />
              <img class="integration-status" :src="company && company.motive_refreshtoken
                ? require('@/assets/active-state.svg')
                : require('@/assets/inactive-state.svg')
                " />
              <p class="mt-4 text-tertiary">
                Motive integration will integrate your drivers, vehicles and
                trailers to our platform.
              </p>
              <hr />
              <div class="text-right">
                <p class="text-main cursor-pointer mb-0" @click="$emit('motiveStatus', true)">
                  View Integration
                </p>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="6" class="mb-3 mb-md-0 mt-30-px">
          <b-card class="table-custom-border h-100">
            <b-card-text class="position-relative">
              <img :src="require('@/assets/ps.svg')" width="208" height="50px" />
              <img class="integration-status" :src="company && company.ps_token
                ? require('@/assets/active-state.svg')
                : require('@/assets/inactive-state.svg')
                " />
              <p class="mt-4 text-tertiary">
                Platform Science integration will integrate your drivers and vehicles to our platform.
              </p>
              <hr />
              <div class="text-right">
                <p class="text-main cursor-pointer mb-0" @click="$emit('psStatus', true)">
                  View Integration
                </p>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="6" class="mb-3 mb-md-0 mt-30-px">
          <b-card class="table-custom-border h-100">
            <b-card-text class="position-relative">
              <img :src="require('@/assets/isaac.svg')" width="208" height="50px" />
              <img class="integration-status" :src="company && company.isaac_auth_code
                ? require('@/assets/active-state.svg')
                : require('@/assets/inactive-state.svg')
                " />
              <p class="mt-4 text-tertiary">
                Isaac integration will integrate your drivers, vehicles and trailers to our platform.
              </p>
              <hr />
              <div class="text-right">
                <p class="text-main cursor-pointer mb-0" @click="$emit('isaacStatus', true)">
                  View Integration
                </p>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="6" class="mb-3 mb-md-0 mt-30-px">
          <b-card class="table-custom-border h-100">
            <b-card-text class="position-relative">
              <img :src="require('@/assets/geotab.svg')" width="208" height="50px" />
              <img class="integration-status" :src="company && company.geotab_database
                ? require('@/assets/active-state.svg')
                : require('@/assets/inactive-state.svg')
                " />
              <p class="mt-4 text-tertiary">
                Geotab integration will integrate your drivers, vehicles and trailers to our platform.
              </p>
              <hr />
              <div class="text-right">
                <p class="text-main cursor-pointer mb-0" @click="$emit('geoTabStatus', true)">
                  View Integration
                </p>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <SamsaraIntegration :status="!!company.samsara_refreshtoken" @close="$emit('samsaraStatus', false)"
      v-if="showInnerSamsaraPage" />
    <MotiveIntegration :status="!!company.motive_refreshtoken" @close="$emit('motiveStatus', false)"
      v-if="showInnerMotivePage" />
    <GeoTabIntegration v-if="showInnerGeoTabPage" @close="$emit('geoTabStatus', false)"
      :status="!!company.geotab_database" />
    <IsaacIntegration v-if="showInnerIsaacPage" @close="$emit('isaacStatus', false)"
      :status="!!company.isaac_auth_code" />
      <PSIntegration v-if="showInnerPsPage"
      @close="$emit('psStatus', false)"
      :status="!!company.ps_token"
      />
  </div>
</template>
<script>
import SamsaraIntegration from "./SamsaraIntegration.vue";
import MotiveIntegration from "./MotiveIntegration.vue";
import GeoTabIntegration from "./GeoTabIntegration.vue";
import IsaacIntegration from "./IsaacIntegration.vue";
import PSIntegration from "./PSIntegration.vue";
export default {
  name: "Integrations",
  props: ["company", "showInnerGeoTabPage", "showInnerSamsaraPage", "showInnerMotivePage", "showInnerIsaacPage", "showInnerPsPage"],
  components: {
    SamsaraIntegration,
    MotiveIntegration,
    GeoTabIntegration,
    IsaacIntegration,
    PSIntegration
  },
};
</script>
<style>
.integration-status {
  position: absolute;
  right: 0;
}

.mt-30-px {
  margin-top: 30px !important;
}
</style>
