<template>
  <b-container fluid class="form register-form">
    <b-row>
      <b-col lg="5" md="5" sm="12" class="left-side" :class="step === 1 || step === 3 ? 'vh-100' : ''">
        <b-row>
          <b-col lg="12" md="9" sm="12" class="m-md-0 my-md-5 my-5">
            <div class="fix-width register">
              <div class="logo mb-3 pb-3 text-left">
                <b-img class="" :src="require('@/assets/new-logo.svg')" width="245" height="70"></b-img>
              </div>
              <div class="heading text-left mb-4">
                <h1 class="mb-0">Register account</h1>
                <p>Get your Digital Permit Book, account now.</p>
              </div>
              <Step1 :checkUserEmailExists="checkUserEmailExists" :user="user" v-if="step === 1" :userExists="userExists"
                :onLogin="onLogin" :newUser="newUser" :verifyUserEmail="verifyUserEmail" />
              <Step2 :onSignUp="onSignUp" :user="user" v-else-if="step === 2" />
              <Step3 :getCompanyDetailsfromUsdot="getCompanyDetailsfromUsdot" v-else-if="step === 3" :company="company" />
              <Step4 :company="company" v-else :createUserCompany="createUserCompany" @getStates="getStates($event)" />
              <div class="signup mt-3">
                By registering you agree to the
                <a href="https://www.digitalpermitbook.com/terms-and-conditions" target="_blank">Terms of Use</a>
              </div>
              <div class="signup mt-3 text-center">
                Already have an account?
                <router-link to="/login">Login</router-link>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="7" md="7" class="p-0 auth-bg-img">
        <Auth class="" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Auth from "@/components/auth/Auth.vue";
import Step1 from "@/components/auth/Registration/Step1.vue";
import Step2 from "@/components/auth/Registration/Step2.vue";
import Step3 from "@/components/auth/Registration/Step3.vue";
import Step4 from "@/components/auth/Registration/Step4.vue";
import { mapActions } from "vuex";
import axios from "axios"
export default {
  components: { Auth, Step1, Step2, Step3, Step4 },
  name: "Register",
  data() {
    return {
      step: 1,
      companyDetails: null,
      userExists: false,
      newUser: false,
      states: [],
      userKey: "",
      company: {
        usdot: "",
        company_name: "",
        company_id: "",
        address: "",
        city: "",
        state: null,
        zipcode: "",
        phone_number: ""
      },
      user: {
        otp: "",
        last_name: "",
        first_name: "",
        email: "",
        password1: "",
        password2: "",
      },
    };
  },
  methods: {
    ...mapActions(["setNotification", "registerUser", 'createCompanyOnly', "checkAdminUserExists", 'getCompanyDetails', 'updateCompany', 'loginUser', 'setLoader', 'verifyOtp']),
    async onLogin() {
      try {
        const { key } = await this.loginUser({
          payload: {
            email: this.user.email,
            password: this.user.password1
          },
          registerCompany: true
        },);
        if (key) {
          this.userKey = key
          this.step = 3
        }
      }
      catch (error) {
        this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    async onSignUp() {
      try {
        delete this.user.otp
        this.setLoader(true)
        const { key } = await this.registerUser(this.user)
        this.userKey = key
        this.step++
        this.setLoader(false)
      }
      catch (error) {
        this.setLoader(false)
        return this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    async verifyUserEmail() {
      try {
        await this.verifyOtp({
          email: this.user.email,
          otp: this.user.otp
        })
        this.setNotification({
          msg: "Otp Verified Successfully",
          type: "success",
          color: "green"
        })
        this.step = 2
      }
      catch (e) {
        this.setNotification({
          msg: "Invalid Otp",
          type: "error",
          color: "red",
        })
      }
    },
    getStates(states) {
      this.states = states
    },
    async checkUserEmailExists() {
      try {
        const { status } = await this.checkAdminUserExists({ email: this.user.email })
        if (status === 'OTP sent to your email.') {
          this.newUser = true
          this.setNotification({
            msg: "Otp Sent for Verification",
            type: "success",
            color: "green",
          });
        }
        else if (status === 'User with this email already exists.') this.userExists = true

      }
      catch (e) {
        this.userExists = false
        if (e.data.status === 'User is not an admin.') {
          this.setNotification({
            msg: "Only Admins are allowed to create a company",
            type: "error",
            color: "red",
          });
        }
      }
    },
    async getCompanyDetailsfromUsdot() {
      delete axios.defaults.headers.common['Authorization']
      await axios.get(`https://mobile.fmcsa.dot.gov/qc/services/carriers/${this.company.usdot}?webKey=f5227198c16bc9926b6e86cd10131e62bdc1424a`).then((res) => {
        const { legalName, phyStreet, phyCity, phyState, phyZipcode } = res.data.content.carrier
        this.company = { ...this.company, company_name: legalName, address: phyStreet, city: phyCity, state: phyState, zipcode: phyZipcode }
      }).catch((e) => {
        console.log(e)
      }).finally(() => {
        this.step++
      })
    },
    isNumber(value) {
      return typeof value === 'number' && !Number.isNaN(value) && Number.isFinite(value);
    },
    getState(state) {
      return this.states.filter((item) => item.label === state)[0].id || state
    },
    async createUserCompany() {
      try {
        this.setLoader(true)
        await this.createCompanyOnly({data:{...this.company, state:this.isNumber(this.company.state) ? this.company.state: this.getState(this.company.state)},key:this.userKey})
        localStorage.setItem("token", this.userKey);
        await this.setNotification({
          msg: "Company Created Successfully",
          type: "success",
          color: "green",
        });
        this.setLoader(false)
        this.$router.push("/all-companies");

      }
      catch (error) {
        this.setLoader(false)
        if (error.status || error.name || error.error)
          this.setNotification({
            msg: "Company with same name already exists.",
            type: "error",
            color: "red",
          });
      }
    },
    payloadToFormData(payload) {
      let formData = new FormData();
      for (let [key, value] of Object.entries(payload)) {
        if (key === "logo" && this.companyLogo) {
          formData.append(key, this.companyLogo, this.companyLogo.name);
        }
        else if (key !== "logo") {
          if (value !== null) {
            formData.append(key, value);
          }
        }

      }
      return formData;
    },
  },
};
</script>

<style>
@media (min-width: 1050px) and (max-width: 1700px) {
  .register-form .right-img {
    width: 100%;
  }

  /* .register-form .right-img img {
    height: 100%;
  } */
}

@media screen and (min-width: 1701px) {
  .auth-bg-img {
    height: 100vh;
  }
}

@media screen and (max-width: 1700px) {
  .auth-bg-img {
    height: auto;
  }
}

@media screen and (max-width: 1200px) {
  .auth-bg-img {
    height: auto;
  }
}

@media (max-width: 1210px) {
  .fix-width {
    width: auto !important;
  }

  /* .right-side img {
    height: 100%;
    object-fit: cover;
  } */
  .auth-bg-img {
    height: auto;
  }
}

@media (max-width: 991px) {
  .fix-width.register {
    width: 300px !important;
  }

  /* .right-img.right-side {
    height: 100%  !important;
  }
  .right-img.right-side img {
    height: 100%  !important;
  } */
}

@media (min-width: 768px) and (max-width: 1024px) {
  .auth-bg-img {
    height: calc(100vh - -60px);
  }
}

@media (max-width: 767px) {
  .auth-bg-img {
    display: none;
  }
}

@media (max-width: 575px) {
  .fix-width {
    width: auto !important;
  }
}

.password-2 .b-icon {
  top: 14px !important;
}
</style>
