import {
  getReq,
  postReq,
  postFormData,
  patchReq,
  deleteReq,
  getById
} from "../../../services/api";

const state = {
  allEquipments: [],
  equipmentDocuments: [],
  currentEquipment: {},
  currentMaintenance: {},
  currentMaintenanceSchedule: {},
  maintenance: [],
  maintenanceSchedule: [],
};

const getters = {
  allEquipments: (state) => state.allEquipments,
  equipmentDocuments: (state) => state.equipmentDocuments,
  currentEquipment: (state) => state.currentEquipment,
  maintenance: (state) => state.maintenance,
  maintenanceSchedule: (state) => state.maintenanceSchedule,
  currentMaintenance: (state) => state.currentMaintenance,
  currentMaintenanceSchedule: (state) => state.currentMaintenanceSchedule
};

const actions = {
  async getAllEquipments({ commit }, url) {
    try {
      const data = await getReq(`app/equipment/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      commit("setAllEquipments", data.equipment);
      return { records: data.equipment, total_pages: data.total_pages, queryset_count: data.queryset_count, total_entries: data.total_entries_count, current_page: data.current_page }
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getEquipment({ commit }, id) {
    try {
      const currentEquipment = await getReq(`app/equipment/${id}/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      console.log("get");
      commit("setCurrentEquipment", currentEquipment);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async searchEquipment(_, query) {
    try {
      return await getReq(`app/search-equipment/?${query}`);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response;
    }
  },
  async getEquipmentsExportData(_, query) {
    try {
      return await getReq(`app/export_excel_equipment/${query ? "?" + query : ''}${localStorage.getItem("userSelectedCompany") ? `${query ? '&' : ''}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response;
    }
  },
  async getEquipmentCount(_, query) {
    try {
      const data = await getReq(
        `app/equipment_count/?equipment_choice=${query}${localStorage.getItem("userSelectedCompany") ? `&company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`
      );
      return data;
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response;
    }
  },
  async addEquipment(context, payload) {
    try {
      const newMember = await postReq(`app/equipment/`, payload);
      console.log("post");

      return newMember;
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response.data;
    }
  },
  async getCompanyEquipmentDetails(_, payload) {
    try {
      console.log(payload)
      const { data } = await postReq(`app/get-equipment-details/`, payload);
      return data
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async updateEquipment({ commit }, { id, payload }) {
    try {
      const updatedEquipment = await patchReq("app/equipment", id, payload);
      if (updatedEquipment) {
        commit("setCurrentEquipment", updatedEquipment);
      }
      return updatedEquipment
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },
  async sendEquipmentDocument(_, formData) {
    try {
      return await postFormData("app/documents", formData);
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },
  async sendMaintenanceDocument(_, {formData, id}) {
    console.log(id)
    try {
      return await postFormData(`app/maintenance/${id}/documents/?company_id=${localStorage.getItem("userSelectedCompany")}`, formData);
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },
  async updateMaintenanceDocument(_, {formData, id, doc}) {
    console.log(formData)
    try {
      return await patchReq(`app/maintenance/${id}/documents`, doc,formData);
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },
  async retrieveMaintenanceDocument(_, {id, doc}) {
    try {
      return await getById(`app/maintenance/${id}/documents/`, doc);
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },
  async deleteMaintenanceDocument(_,{id, doc}) {
    try {
      return await deleteReq(`app/maintenance/${id}/documents`, doc);
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },
  
  async deleteEquipment(_, id) {
    try {
      return await deleteReq("app/equipment", id);
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },

  async updateEquipmentDocument(_, { id, payload }) {
    try {
      return await patchReq("app/documents", id, payload);
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },
  async updateEquipmentStatus(context, payload) {
    try {
      const newEquipment = await postReq(`app/change-equipment-status/`, payload);
      return newEquipment;
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response.data;
    }
  },
  async deleteEquipmentDocument(_, id) {
    try {
      return await deleteReq("app/documents", id);
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },
  async getMaintenance({ commit }, url) {
    try {
      const data = await getReq(`app/maintenance/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      commit("setMaintenance", data.data);
      return { records: data.data, total_pages: data.total_pages, queryset_count: data.queryset_count, total_entries: data.total_entries_count, current_page: data.current_page,total_amount:data.total_amount }
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getMaintenanceSchedule({ commit }, url) {
    try {
      const data = await getReq(`app/maintenance-schedule/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      commit("setMaintenanceSchedule", data.data);
      return { records: data.data, total_pages: data.total_pages, queryset_count: data.queryset_count, total_entries: data.total_entries_count, current_page: data.current_page }
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getMaintenanceTotal(_,url) {
    try {
      const data = await getReq(`app/maintenance-total/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      return { records: data.data, total_pages: data.total_pages, queryset_count: data.queryset_count, total_entries: data.total_entries_count, current_page: data.current_page,total_amount:data.total_amount }
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getMaintenanceRecordsExportData(_, query) {
    try {
      return await getReq(`app/maintenance/export_to_csv/${query ? "?"+query : ''}${localStorage.getItem("userSelectedCompany") ? `${query ? '&':''}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response;
  }
},
async getMaintenanceTotalRecordsExportData(_, query) {
  try {
    return await getReq(`app/maintenance-total/export_to_csv/${query ? "?"+query : ''}${localStorage.getItem("userSelectedCompany") ? `${query ? '&':''}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
  } catch (error) {
    if (error.response.status === 403) {
      throw "You are not allowed to perform this action.";
    }
    throw error.response;
}
},
async getRecurringSchedule(_, url) {
  try {
    const data = await getReq(`app/recurring-maintenance-schedule/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
    return { records: data.data, total_pages: data.total_pages, queryset_count: data.queryset_count, total_entries: data.total_entries_count, current_page: data.current_page,total_amount:data.total_amount }
  } catch (error) {
    if (error.response.status === 403) {
      throw "You are not allowed to perform this action.";
    }
    console.log(error.response);
    throw error.response;
  }
},
async addRecurringScheduleData(context, payload) {
  try {
    await postReq(`app/recurring-maintenance-schedule/`, payload);
  } catch (error) {
    if (error.response.status === 403) {
      throw "You are not allowed to perform this action.";
    }
    console.log(error.response);
    throw error.response;
  }
},
async getMaintenanceScheduleExportData(_, query) {
  try {
    return await getReq(`app/maintenance-schedule/export_to_csv/${query ? "?"+query : ''}${localStorage.getItem("userSelectedCompany") ? `${query ? '&':''}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
  } catch (error) {
    if (error.response.status === 403) {
      throw "You are not allowed to perform this action.";
    }
    throw error.response;
}
},
  async addBulkMaintenanceRecords(context, payload) {
    try {
      console.log(payload);
      const bulkData = await postReq(`app/maintenance/import_from_csv/?company_id=${localStorage.getItem("userSelectedCompany")}`, payload);
      return bulkData;
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  async addBulkMaintenanceSchedule(context, payload) {
    try {
      console.log(payload);
      const bulkData = await postReq(`app/maintenance-schedule/import_from_csv/?company_id=${localStorage.getItem("userSelectedCompany")}`, payload);
      return bulkData;
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  async postMaintenance(_, payload) {
    try {
      const record = await postReq("app/maintenance/", payload);
      return record
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async postMaintenanceSchedule(_, payload) {
    try {
      await postReq("app/maintenance-schedule/", payload);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async patchMaintenance(_, { id, payload }) {
    try {
      await patchReq("app/maintenance", id, payload);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async patchMaintenanceSchedule(_, { id, payload }) {
    try {
      return await patchReq("app/maintenance-schedule", id, payload);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async deleteMaintenance(_, id) {
    try {
      await deleteReq("app/maintenance", id);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async deleteRecurringSchedule(_, id) {
    try {
      await deleteReq("app/recurring-maintenance-schedule", id);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async deleteMaintenanceSchedule(_, id) {
    try {
      await deleteReq("app/maintenance-schedule", id);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
 
  async getMaintenanceRecord({ commit }, id) {
    console.log(id)
    try{
      const maintenance = await getReq(`app/maintenance/${id}/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      commit("setCurrentMaintenance", maintenance)
    }
      catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getRecurringScheduleRecord(_, id) {
    try{
      const data = await getReq(`app/recurring-maintenance-schedule/${id}/`);
      return data
    }
      catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async updateRecurringScheduleRecord(_, { id, payload }) {
    try {
      return await patchReq("app/recurring-maintenance-schedule", id, payload, null, false);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async addEquipmentBulkTags(_, payload) {
    try {
      const response = await postReq("app/equipment-add-tags-bulk/",payload);
      return response;
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response;
    }
  },
  setMaintenanceSchedule({ commit }, maintenanceSchedule) {
    commit("setCurrentMaintenanceSchedule", maintenanceSchedule)
  }
};

const mutations = {
  setAllEquipments: (state, allEquipments) => (state.allEquipments = allEquipments),
  setCurrentEquipment: (state, Equipment) =>
    (state.currentEquipment = Equipment),
  setEquipmentDocuments: (state, equipmentDocuments) =>
    (state.equipmentDocuments = equipmentDocuments),
  setMaintenance: (state, maintenance) => (state.maintenance = maintenance),
  setMaintenanceSchedule: (state, maintenanceSchedule) =>
    (state.maintenanceSchedule = maintenanceSchedule),
  setCurrentMaintenance: (state, currentMaintenance) => state.currentMaintenance = currentMaintenance,
  setCurrentMaintenanceSchedule: (state, currentMaintenanceSchedule) => state.currentMaintenanceSchedule = currentMaintenanceSchedule
};

export default {
  state,
  getters,
  actions,
  mutations,
};
