<template>
    <div>
        <b-modal id="doc-media-popup" no-close-on-backdrop size="lg" scrollable centered
            :footer-class="'d-block border-0 px-20px pt-0 pb-3 m-0'" no-close-on-esc :header-class="'align-items-center'">
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Preview
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
                <b-dropdown id="dropdown-media-poup" class="load-dropdown mt-0">
                    <template slot="button-content">
                        <b-icon class="dots" icon="three-dots"></b-icon>
                    </template>
                    <b-dropdown-item-button :class="!deleteItemPermission ? 'mb-2' : ''"
                        @click="downloadFile(media)">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Download</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button class="mb-2" v-if="mediaType === 'pdf'"
                        @click="printFile(media)">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Print</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button v-if="!deleteItemPermission">
                        <p class="text_secondary cursor-pointer mb-0 f-14" @click="showPopup(mediaID)">Delete</p>
                    </b-dropdown-item-button>
                </b-dropdown>
            </template>
            <vue-pdf-embed v-if="mediaType === 'pdf'" :source="media" />
            <video controls autoplay v-else-if="mediaType === 'video'" class="w-100">
                <source :src="media" type="video/mp4">
                <source :src="media" type="video/ogg">
                Your browser does not support the video tag.
            </video>
            <img :src="media" v-else class="w-100" />
            <template #modal-footer>
                <div class="d-flex modal-footer border-0 p-0 justify-content-end mt-3">
                    <b-button class="btn cancel m-0" block @click="$bvModal.hide('doc-media-popup')">Close</b-button>
                </div>
            </template>
        </b-modal>
        <DeleteModal v-if="showDeleteModal" @confirm="deleteItem($event)" />
    </div>
</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import DeleteModal from "@/components/DeleteModal.vue";
export default {
    name: "MediaPopup",
    props: ["mediaID", "media", "mediaType", 'deleteDocItem', 'deleteItemPermission', 'docName'],
    components: { DeleteModal, VuePdfEmbed },
    data() {
        return {
            showDeleteModal: false,
            itemId: null
        }
    },
    methods: {
        deleteItem(status) {
            if (status) {
                this.deleteDocItem(status, this.itemId)
                this.$bvModal.hide('doc-media-popup')
            }
        },
        printFile(url){
            window.open(url, '_blank');
        },
        downloadFile(url) {
            fetch(url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob();
                })
                .then(blob => {
                    const newUrl = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = newUrl;
                    a.download = this.docName
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(newUrl);
                    a.remove()
                })
                .catch(error => console.error('There has been a problem with your fetch operation:', error));
        },
        showPopup(id) {
            this.showDeleteModal = true;
            this.itemId = id;
        },
    },
}
</script>